<template>
  <div class="content">
    <el-row style="padding: 15px 0;height:75px;line-height: 45px;">
      <el-col :span="17">
        <div style="display: flex;">
          <h2>成员详情</h2>
<!--          <el-button style="margin-left: 20px;" type="text"><i class="el-icon-arrow-left"></i>返回团队</el-button>-->
        </div>
      </el-col>
      <el-col :span="6" style="display: inline-flex;justify-content: right;align-items: center" :offset="1">
        <el-avatar :src="infoData.headimg" :size="60"></el-avatar>
        &nbsp; &nbsp;
        <div>{{ infoData.nickname ? infoData.nickname : infoData.phone }}</div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 20px;">
      <el-col :span="18">
        <el-card style="margin-right: 20px;">
          <div slot="header" class="clearfix">
            <i style="line-height: 22px;" class="el-icon-s-data"></i>&nbsp;<span>数据统计</span>
            &nbsp;
            <el-tooltip class="item" effect="dark" :content="echartsData.tips" placement="top" v-if="echartsData.tips">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          <el-row :gutter="20">
            <el-col :span="5" v-for="(gsVal,gsValIndex) in groupState" :key="gsValIndex" v-if="gsVal.name">
              <div class="border-base">
                <i :class="'el-icon-'+gsVal.icon" v-if="gsVal.icon"></i>
                <i v-else>{{gsVal.iconText}}</i>
                <el-statistic group-separator="," :value="gsVal.value || 0" :title="gsVal.name"></el-statistic>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20" style="margin-top: 20px;">
            <el-col :span="11">
              <diy-echarts class="border-base" v-bind="echartsData.acData"></diy-echarts>
            </el-col>
            <el-col :span="13">
              <diy-echarts class="border-base" v-bind="echartsData.timeData" :type="'zhu'"></diy-echarts>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <div slot="header" class="clearfix">
            <span>个人信息</span>
          </div>
          <ul class="ul" style="min-height:240px;" v-if="timeList.length > 0">
            <li class="li" v-for="timeVa,i in timeList" :key="i">
              <i :class="'el-icon-'+timeVa.icon" v-if="timeVa.icon"></i>
              <el-image style="width:20px;height:20px;" :src="timeVa.img || imgData[timeVa.type]" v-else></el-image>
              <div style="margin-left: 10px;">
                最近{{ timeVa.name }}时间：{{ timeVa.time }}
              </div>
            </li>
          </ul>
          <el-empty :description="'暂无数据'" v-else></el-empty>
        </el-card>
        <el-card style="margin-top: 20px;">
          <div slot="header" class="clearfix">
            <span>成就信息</span>
          </div>
          <ul class="ul">
            <li class="li" v-for="(aVa,Akey) in achievementData" :key="Akey">
              <i :class="'el-icon-'+aVa.icon" v-if="aVa.icon"></i>
              <el-image style="width:20px;height:20px;" :src="aVa.img" v-else></el-image>
              <div style="margin-left: 10px;">
                {{ aVa.name }}：{{aVa.value || 0}}
              </div>
            </li>
          </ul>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {userInfo} from "@/api/me";
import DiyEcharts from "@/components/com/el/diyEcharts";

export default {
  components: {DiyEcharts},
  props: {
    team_id:{
      default: () => 0,
    },
    user_id:{
      default: () => 0,
    },
    teamInfo:{
      type:Object,
      default: () => { return {}; },
    },
  },
  data(){
    return {
      where:{},
      infoData: {},
      groupState:{
        quest_count: {
          name:'评测评提交题目数',
          icon:'notebook-1',
          value:0,
        },
        quest_times_count:{
          name:'评测提交次数',
          icon:'notebook-1',
          value:0,
        },
        ac_quest_count:{
          name:'全AC题目数',
          iconText:'AC',
          value:0,
        },
        right_count:{
          name:'全AC测试点数',
          icon:'s-promotion',
          value:0,
        },
        questlist_count:{
          name:'评测提交题单数',
          icon:'copy-document',
          value:0,
        }
      },
      timeList:[
        {icon:'message-solid',name:"答题",time:'2022-09-23 20:44',type:'answer',times:1},
        {icon:'bell',name:"登录",time:'2022-09-21 18:01',type:'login',times:1},
      ],
      imgData: {
        answer:require('@/assets/images/me/预览@3x.png'),
        login:require('@/assets/images/me/预览@3x.png'),
      },
      achievementData:{
        views_count:{
          name:'阅读总数',
          img_color:'#00957f',
          unmText: "总+",
          value:0,
          img:require('@/assets/images/me/预览@3x.png')
        },
        praise_count:{
          name:'点赞总数',
          img_color:'#f5a544',
          unmText: "总+",
          value:0,
          img:require('@/assets/images/me/点赞@3x.png')
        },
        collects_count:{
          name:'获得收藏',
          img_color:'#ee8a68',
          unmText: "总+",
          value:0,
          img:require('@/assets/images/me/收藏@3x.png')
        },
      },
      echartsData:{
        tips:"数据统计提示",
        acData:{
          name:'全AC题目数',
          data:[[
            { value: 0, name: '其他' },
          ]]
        },
        timeData:{
          name:'测评时间分布',
          options:{labels:['0-4','4-8','8-12','12-16','16-20','20-24'],},
          data:[]
        },
      }
    }
  },
  methods:{
    routeUpdate(){
      this.where = {...this.where,team_id:this.team_id,user_id: this.user_id,...this.$route.query};
      this.getInfo();
      this.getGroupState();
      this.geteChartsData();
      this.getTimeLog();
    },
    getInfo(){
      userInfo({ team_id:this.team_id,student_user_id: this.user_id,achievementFields:Object.keys(this.achievementData) }).then(({data}) => {
        let {achievementData , ...info} = data.info || {};
        this.infoData = info
        let $achievementData = this.initObjData(info , this.achievementData , 'value' , 0);
        if(achievementData){
          this.achievementData = this.initObjData(achievementData , $achievementData , 'value' , 0);
        }else{
          this.achievementData = {...$achievementData};
        }
      }).catch()
    },
    getGroupState(){
      this.$http.post('/xapi/user.group/groupState' , {...this.where,fields:Object.keys(this.groupState)}).then(({data})=>{
        this.groupState = this.initObjData(data , this.groupState , 'value' , 0);
      }).catch();
    },
    getTimeLog(){
      this.$http.post('/xapi/user.group/groupLogList' , this.where).then(({data})=>{
        this.timeList = data.data || []
      }).catch();
    },
    geteChartsData(){
      this.$http.post('/xapi/user.group/getChartsData',{...this.where,fields:Object.keys(this.echartsData)}).then(({data})=>{
        if(this.othersData.echartsData === undefined){
          this.othersData.echartsData = {...this.echartsData};
        }
        this.echartsData = this.initObjData(data , this.echartsData , 'data' , (obj , objKey)=>{
          if((obj[objKey].data || []).length === 0 && (this.othersData.echartsData[objKey].data || []).length > 0){
            obj[objKey].data = [...this.othersData.echartsData[objKey].data];
          }
        });
      }).catch();
    },
    initObjData(data , oldObj , key = 'data' , $default = null , isOnly = true){
      var obj = {};
      for (const objKey in data) {
        if( typeof oldObj[objKey] === 'object'){
          if(oldObj[objKey] instanceof Array){
            obj[objKey] = data[objKey] instanceof Array ? [...data[objKey]]: [...(data[objKey][key] || [])];
          }else{
            let $obj = {};
            if(data[objKey] instanceof Array){
              $obj[key] = [...data[objKey]];
              obj[objKey] = {...oldObj[objKey],...$obj};
            }else if($obj[key] instanceof Object){
              $obj[key] = data[objKey][key] === undefined || data[objKey][key] === null || data[objKey][key] === '' && $default instanceof Object ? $default : data[objKey][key];
              obj[objKey] = {...oldObj[objKey],...data[objKey],...$obj};
            }else{
              $obj[key] = data[objKey];
              obj[objKey] = {...oldObj[objKey],...$obj};
            }
          }
          $default && typeof $default === 'function' && $default(obj , objKey);
        }else if(!isOnly || (isOnly && oldObj[objKey] !== undefined)){
          obj[objKey] = data[objKey]
        }
      }
      return {...oldObj,...obj}
    }
  }
}
</script>

<style scoped lang="less">
::v-deep{
  .el-col-5{
    width: 20%;
  }
  .border-base{
    text-align: center;
    border-radius: 20px;
    padding: 10px;
    border:1px solid #ccc;
    i{
      font-style: normal;
      speak: none;
      text-transform: none;
      line-height: 1;
      vertical-align: baseline;
      display: inline-block;
      background-color: #ffe2d4;
      border-radius: 50%;
      font-size: 30px;
      font-weight: bold;
      padding: 20px;
      color: #ee8a68;
      margin-bottom: 10px;
    }
  }
  .ul{
    line-height: 20px;
    > .li{
      display: flex;
      font-size: 85%;
      [class*=" el-icon-"], [class^=el-icon-]{
        line-height: 20px;
      }
    }
    > .li:not(:last-child){
      margin-bottom: 10px;
    }
  }
  .el-empty{
    padding: 10px 0;
  }
}
</style>